// @ts-strict-ignore
import Link from 'next/link';
import i18n from 'src/utils/translate';
import PostListCard from 'src/modules/community/shared/components/sidebar/postListCard';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { useSelector } from 'src/store/store';
import Icon from 'src/components/icon';
import { PinnedPost } from 'src/constants/types';

type Props = {
  posts: PinnedPost[];
}
export default function PinnedPosts({ posts = [] }: Props): JSX.Element {
  const { currentCommunity } = useSelector(({ context }) => context);

  if (posts.length === 0) return null;

  const SeeAllLink = () => (
    <Link
      as={`/${currentCommunity.slug}/posts#pinned`}
      href={`/community/posts?communitySlug=${currentCommunity.slug}#pinned`}
      onClick={() => {
        trackFootfallEvent(Event.Clicked, {
          clickType: 'see-all-pinned-posts',
          clickMetadata: { section: Section.PostsSideBar },
        });
      }}
    >
      {i18n.t('See all')} <Icon icon="open-right" />
    </Link>
  );

  return (
    <PostListCard
      posts={posts}
      postType={'pinned-post'}
      seeAllLink={<SeeAllLink />}
      title={i18n.t('Pinned Posts')}
    />
  );
}
