import { ReactNode } from 'react';
import styled from 'styled-components';
import { text, titleMedium } from 'styles-js/mixins/typography';

type Props = {
  className?: string;
  children: ReactNode;
  seeAllLink: ReactNode;
  title: string;
} | {
  className?: string;
  children: ReactNode;
  seeAllLink?: never; // seeAllLink requires title
  title?: string;
}
export default function Card({ className = '', children, title, seeAllLink }: Props): JSX.Element {
  return (
    <div className={`community__widget ${className}`}>
      {title && (
        <Header>
          <h2>{title}</h2>
          {seeAllLink}
        </Header>
      )}
      <Body>
        {children}
      </Body>
    </div>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: ${({ theme }) => theme.greyLine};

  h2 {
    ${titleMedium}
    margin: 0 0 5px;
  }
`;
const Body = styled.div`
  ${text}
  padding: 10px 0;
`;
