// @ts-strict-ignore
import Link from 'next/link';
import styled from 'styled-components';
import { slugify } from 'underscore.string';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';
import Card from 'src/components/card';
import config from 'config/config';
import { PostType } from 'src/constants/types';
import Icon from 'src/components/icon';
import PostLink from 'src/modules/shared/links/postLink';

type Props = {
  widget: {
    title: string;
    pollId: string;
  }
}
export default function CommunityLatestPoll({ widget }: Props): JSX.Element {
  const { slug } = useSelector(({ context }) => context.currentCommunity);

  if (!widget?.pollId) return null;

  const SeeAllLink = () => (
    <Link
      as={`/${slug}/polls`}
      href={`/community/communityPosts?communitySlug=${slug}`}
      shallow={true}
      onClick={() => {
        window.scrollTo(0, 0);
        trackFootfallEvent(Event.Clicked, {
          clickType: 'community-see-all-polls',
          clickMetadata: { section: Section.CommunitySideBar },
        });
      }}
    >
      {i18n.t('See all polls')} <Icon icon="open-right" />
    </Link>
  );

  const onClick = (element: Element) => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'community-latest-poll',
      clickMetadata: {
        section: Section.CommunitySideBar,
        element,
      },
    });
  };

  return (
    <Card
      seeAllLink={<SeeAllLink />}
      title={i18n.t('Latest poll')}
    >
      <div className="community__widget__latest-poll">
        <CardHeading>
          <IconWrapper>
            <PollGraphic alt="Poll icon" src={`${config.cdn.static}img/icon-poll.png`} />
          </IconWrapper>
          <Heading className="results-post__title">
            <PostLink
              communitySlug={slug}
              postId={Number(widget.pollId)}
              postTypeId={PostType.Poll}
              urlEncodedTitle={slugify(widget.title)}
              onClick={() => onClick(Element.PollTitle)}
            >
              {widget.title}
            </PostLink>
          </Heading>
        </CardHeading>
        <PostLink
          communitySlug={slug}
          postId={Number(widget.pollId)}
          postTypeId={PostType.Poll}
          urlEncodedTitle={slugify(widget.title)}
          onClick={() => onClick(Element.PollButton)}
        >
          {i18n.t('Vote')}
        </PostLink>
      </div>
    </Card>
  );
}

const CardHeading = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const PollGraphic = styled.img`
  width: 100%;
  height: 100%;
`;
const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 30px;
  height: auto;
  margin: 0 10px 4px 5px;
`;
const Heading = styled.h3`
  margin-bottom: 0;
`;
